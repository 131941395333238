import { css } from '@emotion/css';

export const styDesktopView = css`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  justify-content: space-between;

  .desktop-view__left-side {
    display: none;
  }

  .desktop-view__right-side {
    width: 100%;
    max-width: 450px;
    margin: auto;
  }

  @media only screen and (min-width: 1028px) {
    .desktop-view__left-side {
      display: block;
      width: calc(100% - 450px);
    }

    .desktop-view__right-side {
      width: 450px;
      margin: unset;
    }
  }
`;
