import imgBride from '../images/assets/bride.jpg';
import imgGroom from '../images/assets/groom.jpg';
import imgGallery from '../images/assets/gallery.jpg';
import imgFooter from '../images/assets/footer.jpg';
import imgDesktopView from '../images/assets/desktop-view.jpg';
import imgYoutube from '../images/assets/youtube.jpg';
import imgPattern from '../assets/pattern/pattern.png';
import imgPatternMini from '../assets/pattern/mini.png';
import imgLogo from '@/assets/icons/logo.png';
import imgFlowerLive from '@/assets/img/flower-live-streaming.png';
import imgFlowerWedding from '@/assets/img/flower-wedding.png';
import imgFlowerWeddingBottom from '@/assets/img/flower-wedding-bottom.png';
import imgCover from '@/assets/bg/cover.jpg';
import imgGate from '@/assets/img/gate.png';
import imgConfirmation from '../images/assets/bg-confirmation.jpg';
import imgWelcome from '../images/assets/welcome.jpg';
import imgFilter from '../images/assets/filter.jpg';

// BG Images
export const BG_COVER = imgCover;
export const BG_WELCOME = imgWelcome;
export const BG_COUPLE_INFO = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/backround-alternative.jpg?updatedAt=1687666046270`;
export const BG_WEDDING_INFO = `https://ik.imagekit.io/invitatoassets1/Berny___Gunung/Bernyka___Gunung.jpg?updatedAt=1688693493728`;
export const BG_COUNTING_DOWN = BG_COVER;
export const BG_DESKTOP_VIEW = imgDesktopView;
export const BG_RSVP = BG_COUPLE_INFO;
export const BG_INSTAGRAM_FILTER = imgFilter;

// still not used
export const BG_GUEST_INFO = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const BG_CONFIRMATION = imgConfirmation;

// Welcoming Image
// @TODO: check this image used or not?
export const IMG_PHOTO_1 = ``;
export const IMG_PHOTO_2 = ``;
export const IMG_PHOTO_3 = ``;

// Wedding Detail Image
// @TODO: check this image is used or not
export const IMG_DRESSCODE = `https://user-images.githubusercontent.com/10141928/169198604-51a5748c-b5ca-4a7b-8576-d9bf6945e0d6.jpg`;

// Couple Image
// @TODO: check this image is used or not
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/168402483-62cc3dd6-b5ba-4939-ac3d-cc136bd9bdbe.jpg`;
export const IMG_MAN = imgGroom;
export const IMG_GIRL = imgBride;
export const IMG_WELCOMING_SECTION = `https://user-images.githubusercontent.com/10141928/169065994-eb456e46-d52f-48c7-bea4-bad679d56958.jpg`;
export const IMG_WISHES = BG_GUEST_INFO;
export const IMG_FOOTER = imgFooter;
export const IMG_YOUTUBE_THUMBNAIL = imgYoutube;

// Cover Gallery Image
// @TODO: check this image is used or not
export const IMG_GALLERY_1 = imgGallery;
export const IMG_GALLERY_COVER = IMG_GALLERY_1;

// Logo Section
export const IMG_LOGO = imgLogo;
// @TODO: check this image is used or not
export const IMG_LOGO_INVERT = IMG_LOGO;

/**
 * Image Flower Assets
 * @important - IF YOU WANT TO OVERRIDE THIS ASSETS,
 * MAKE SURE YOU REPLACE WITH SAME RESOLUTION
 */
export const ASSETS_GRAS_MULTIPLE_BOTTOM = `https://ik.imagekit.io/invitato0assets/template-kila-roy/grash-bottom-multiple.png?updatedAt=1690626007024`;
export const ASSETS_GRAS_SINGLE = `https://ik.imagekit.io/invitato0assets/template-kila-roy/gras-single.png?updatedAt=1690626201665`;
export const ASSETS_FLOWER_RIGHT = `https://user-images.githubusercontent.com/10141928/258559198-8eca0829-f1e8-4b25-9d89-60c172a8e4fe.png`;
export const ASSETS_FLOWER_LEFT = `https://user-images.githubusercontent.com/10141928/258559203-8bf39f23-2cab-4432-a6de-dfe1d357b2d4.png`;
export const ASSETS_FLOWER_LEFT_2 = `https://user-images.githubusercontent.com/10141928/260276185-91def587-90d8-4f1c-8c37-62dd0723b85c.png`;
export const ASSETS_BG_PATTERN = `https://user-images.githubusercontent.com/10141928/258560931-a187e299-46e9-4dc8-af10-9153021c4650.jpg`;
export const ASSETS_BG_PATTERN_2 = `https://user-images.githubusercontent.com/10141928/260588660-b2d0048e-5893-46b4-aad8-2fb5b0eb5614.jpg`;
export const ASSETS_PATTERN = imgPattern;
export const ASSETS_PROFILE_BOTTOM = `https://user-images.githubusercontent.com/10141928/258564486-c439f1b9-6448-4477-9d04-ed8eeecd0ceb.png`;
export const ASSETS_PROFILE_OUTER = `https://user-images.githubusercontent.com/10141928/258564480-1f5c7e46-8fd3-46ad-8c64-a1f61f3c7f18.png`;
export const ASSETS_WEDDING_GATE = imgGate;
export const ASSETS_WEDDING_BOTTOM = `https://user-images.githubusercontent.com/10141928/258614800-f99c1baf-7e31-4a73-b00d-059d350c2ddb.png`;
export const ASSETS_WEDDING_BOTTOM_2 = imgFlowerWeddingBottom;
export const ASSETS_WEDDING_LEFT = `https://user-images.githubusercontent.com/10141928/258614801-0b3054cd-ec99-421b-959f-bd8311130e9d.png`;
export const ASSETS_WEDDING_RIGHT = `https://user-images.githubusercontent.com/10141928/258614802-1fc19a5d-f9d1-45f2-9965-4f1b2775f29b.png`;
export const ASSETS_PATTERN_SHORT = imgPatternMini;
export const ASSETS_WISHLIST_LEAF = `https://user-images.githubusercontent.com/10141928/260284830-37d42e63-8c2c-4c96-b5ec-ee3910f43014.png`;
export const ASSETS_FLOWER_LIVE_STREAMING = imgFlowerLive;
export const ASSETS_FLOWER_RIGHT_2 = imgFlowerWedding;

/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};

// Partner Logo
export const LOGO_VENDOR_DALANG = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-dalang_bp5JgCazz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651639908257`;
export const LOGO_VENDOR_PENTONE = `https://ik.imagekit.io/invitatoid/global-assets/logo-partner/partner-pentone-white_4cMUJafwM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654518552744`;
export const LOGO_VENDOR_TIRTOWENING =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-tirtowening_2o6jmuIlN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911787';
export const LOGO_VENDOR_SAMAWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-samawa_KniSfe28i.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911529';
export const LOGO_VENDOR_SADEWA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-sadewa_6J3D8PmsJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708911185';
export const LOGO_VENDOR_prahasta =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-prahasta-white_5L4WQTBlc.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910828';
export const LOGO_VENDOR_FLATTER =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-flatter_SvHO8RoVF.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910005';
export const LOGO_VENDOR_AKSARA =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-aksara-white_Ofc6Xkib-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708909659';
export const LOGO_VENDOR_DREAMWORKS =
  'https://ik.imagekit.io/invitatoid/global-assets/logo-partner/logo-vendor-dreamworks-white_FFlUUi8Dk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1655708910073';
